/* Animations */

@keyframes slide-up {
    from {
        transform: translateY(100%);
        -webkit-transform: translateY(100%);
    }
    100% {
        transform: translateX(inherit);
        -webkit-transform: translateX(inherit);
    }
}

@keyframes slide-down {
    from {
        transform: translateY(-15px);
        -webkit-transform: translateY(-15px);
    }
    100% {
        transform: translateX(inherit);
        -webkit-transform: translateX(inherit);
    }
}

@keyframes slide-left {
    0% {
        transform: translateX(100%);
        -webkit-transform: translateX(100%);
    }
    100% {
        transform: translateX(inherit);
        -webkit-transform: translateX(inherit);
    }
}

@keyframes zoom-in {
    0% {
        transform: scale(0);
        -webkit-transform: scale(0);
    }
    100% {
        transform: scale(1);
        -webkit-transform: scale(1);
    }
}

@keyframes ease-in-zoom-animation {
    0% {
        opacity: 0;
        transform: scale(0)
    }
    100% {
        opacity: 1;
        transform: scale(1)
    }
}

@keyframes menu-animation {
    0% {
        transform: scale(0)
    }

    100% {
        transform: scale(1)
    }
}

@keyframes blur {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
        backdrop-filter: blur(3px);
    }
}

@keyframes back-button-animation {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        opacity: 1;
        transform: translateX(inherit);
    }
}

@keyframes back-arrow-hover {
    80% {
        transform: translateX(-10px);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes page-back {
    0% {
        transform: translateX(-100%)
    }
    100% {
        transform: translateX(inherit)
    }
}

@keyframes input-field {
    0% {
        box-shadow: none;
    }
    50% {
        box-shadow: 0 0 0 2px var(--blue-transparent);
    }
    100% {
        box-shadow: none;
    }
}

@keyframes modal {
    0% {
        transform: scale(0)
    }
    100% {
        transform: scale(1)
    }
}

@keyframes settings-item {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(inherit);
    }
}