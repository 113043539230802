/* Theme Colors */
:root {
    --theme-bg: #E8E5DE;
    --theme: hsl(0, 0%, 0%);
    --theme-dark: hsl(0, 0%, 22%);
    --theme-transparent: rgba(0, 0, 0, 0.5);
}

*::-webkit-scrollbar {
    display: none;
}


/* Calender Custom Styles */

/* Top Toolbar Title */
.fc-toolbar-title {
    font-size: 2.5rem !important;
}

.fc-timegrid-axis {
    border: none !important;
    height: 4.5rem !important;
}

.fc-timegrid .fc-daygrid-day {
    border: none !important;
}

.fc-timegrid-axis-frame {
    width: 70px !important;
}

.fc-timegrid-axis-cushion {
    text-align: center !important;
    max-width: 100px !important;
}

/* All Day Bar */
.fc-timegrid-divider {
    background: none !important;
    border: none !important;
    padding: 15px 0;
}

thead .fc-timegrid-axis {
    display: none;
}

tbody[role=presentation] > tr[role=row] {
    /* display: none !important; */
}

/* Time Slot Lanes */

tr, td {
    border-color: #ddd !important;
}

.fc-timegrid .fc-timegrid-slot {
    height: 4.5rem !important;
}

.fc-timegrid-slot {
    border-top:  1px solid #ddd !important;   /* border-bottom: 1px solid #ddd !important; */
    border-bottom:  1px solid #ddd !important;   /* border-bottom: 1px solid #ddd !important; */
}

.fc-timegrid-slot-lane {
    background: #ffffff !important;
}

.fc-daygrid-day.fc-day-today {
    background: #ffffff !important;
}

.fc-timegrid-slot-minor {
    border: none !important;
}

/* Time Slot Title */
.fc-timegrid-slot-label-frame {
    text-align: center !important;
}

.fc-timegrid-event {
    border-radius: 8px !important;
}

.fc-event-main {
    padding: 15px !important;
    font-size: 1.25rem !important;
}

/* .fc-event-time {
    display: none;
} */

@media (max-width: 480px) {
	.fc_view {
        
    }
}

